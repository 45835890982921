(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("jquery"));
	else if(typeof define === 'function' && define.amd)
		define("survey-jquery", ["jquery"], factory);
	else if(typeof exports === 'object')
		exports["survey-jquery"] = factory(require("jquery"));
	else
		root["Survey"] = factory(root["jQuery"]);
})(this, function(__WEBPACK_EXTERNAL_MODULE_jquery__) {
return 